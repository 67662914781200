export function conditionColor(condition: GeneralCondition) {
  switch (condition) {
    case GeneralCondition.GOOD:
      return "success"
    case GeneralCondition.DECENT:
      return "warning"
    case GeneralCondition.INSUFFICIENT:
      return "yellow-darken-4"
    case GeneralCondition.BAD:
      return "error"
    default:
      return "grey"
  }
}

export function riskProfileColor(riskProfile?: RiskProfile) {
  switch (riskProfile) {
    case RiskProfile.LOW:
      return "success"
    case RiskProfile.MEDIUM:
      return "orange"
    case RiskProfile.HIGH:
      return "red"
    case RiskProfile.VERY_HIGH:
      return "error"
    default:
      return "grey"
  }
}

export function userRoleColor(role: string) {
  switch (role) {
    case "SUPER_ADMIN":
      return "admin"
    case "USER":
      return "primary"
    case "SUPPLIER_MANAGER":
    case "SUPPLIER_MECHANIC":
    case "SUPPLIER_PLANNER":
      return "orange"
    default:
      return "grey"
  }
}

export function brlValueColor(value: BrlValue) {
  switch (value) {
    case BrlValue.GOOD:
      return "success"
    case BrlValue.DECENT:
      return "warning"
    case BrlValue.BAD:
      return "error"
    case BrlValue.REPLACED:
      return "secondary"
    case BrlValue.NA:
      return "primary"
    default:
      return "grey"
  }
}

export function riskScoreColor(value: "low" | "medium" | "high" | "critical") {
  switch (value) {
    case "low":
      return "amber"
    case "medium":
      return "amber-darken-2"
    case "high":
      return "deep-orange-lighten-2"
    case "critical":
      return "red"
    default:
      return "grey"
  }
}

const statusColor = {
  NEW: "#FF5630",
  QUOTATION: "#FFC400",
  QUOTE_AWAIT_APPROVAL: "#FFC400",
  QUOTE_ACCEPTED: "#FFC400",
  QUOTE_REJECTED: "#FFC400",
  IN_ORDER: "#FFC400",
  PROCESSING: "#FFC400",
  DID_NOT_PROCESS: "#FF991F",
  PROCESSED: "#36B37E",
  AWAIT_ACCEPTANCE: "#6554C0",
  ACCEPTED: "#6554C0",
  REJECTED: "#6554C0",
  INVOICED: "#6554C0",
  DONE: "#4C9AFF",
}

export const getTicketStatusColor = (ticketStatus: StatusDescription) => statusColor[ticketStatus] || "#000000"

export const getContractStatusColor = (status: ContractStatus) => {
  switch (status) {
    case ContractStatus.APPROVED:
      return "success"

    case ContractStatus.AWAITING_START_DATE:
    case ContractStatus.AWAITING_APPROVAL:
    case ContractStatus.PARTIALLY_APPROVED:
      return "warning"

    case ContractStatus.EXPIRED:
    case ContractStatus.NO_ACTIVE_PRICE_LISTS:
    case ContractStatus.MANUALLY_ENDED:
      return "grey"

    case ContractStatus.DENIED:
      return "error"
  }
}

export const getPriceListStatusColor = (status: PriceListStatus) => {
  switch (status) {
    case PriceListStatus.AWAITING_START_DATE:
    case PriceListStatus.AWAITING_APPROVAL:
      return "warning"

    case PriceListStatus.EXPIRED:
    case PriceListStatus.MANUALLY_ENDED:
      return "grey"

    case PriceListStatus.DENIED:
      return "error"

    case PriceListStatus.APPROVED:
      return "success"
  }
}
